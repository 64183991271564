import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

function Privacy() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    orderNumberOrPostcode: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const buttonStyle = {
    backgroundColor: isSubmitted ? "rgb(75, 182, 120)" : "#4384f5", // 根据isSubmitted状态设置背景颜色
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.orderNumberOrPostcode)
      errors.orderNumberOrPostcode = "Order Number/Postcode is required";
    if (!formData.message) errors.message = "Message is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // 表单数据有效，可以提交表单
      console.log("Form data:", formData);
      setIsSubmitted(true);
      setFormData({
        name: "",
        email: "",
        orderNumberOrPostcode: "",
        message: "",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <div className="main-container">
      <Header />
      <div className="grayMid">
        <p>Home/privacy</p>
      </div>
      <div className="PrivacyDiv">
        <h2>Refund Policies</h2>
        <p>Here is our detailed refund policies for all eventualities.</p>
        <p>
          <strong className="p-strong">Cancellation of your order</strong>
          <br />
          You are welcome to cancel your order up until the point we have sent
          it to the courier. We send all orders at around 2pm each day so if it
          has already been sent then we are not able to cancel it.
          <br />
          You will be issued a full refund if you request a cancellation before
          your order has been sent.
          <br />
          If your order has been sent already you are welcome to return the
          parcel but at your own expense and we can refund you for the original
          order amount paid.
        </p>
        <p>
          <strong className="p-strong">
            My delivery is late can I get a refund?
          </strong>
          <br />
          We know it is annoying when deliveries are late however once the
          parcel has left us we are almost zero control over when or how it is
          delivered.
          <br />
          We are ONLY able to refund your order if your order has reached 14
          days of none movement on our couriers tracking system. This is not 14
          days from point of ordering, it is 14 days of none movement on the
          tracking system. <br />
          This is the only point our courier classes the parcel as lost as up
          until this point the parcel is still active and can (and has been)
          delivered up to 30 days after we have sent the parcel out. <br />
          You will receive a full refund once this time has passed.
        </p>
        <p>
          <strong className="p-strong">
            Hermes say my parcel has an issue and to contact the sender
          </strong>
          <br />
          Generally this is a generic message and is no real help to anyone, so
          we have to contact Hermes and wait for a response as to why there is
          an issue.
          <br />
          Generally it is because the parcel has been damaged and can not be
          delivered.
          <br />
          Once we have received a response from Hermes we will be able to
          resolve and offer you a full refund on the order.
        </p>
        <p>
          <strong className="p-strong">
            Hermes told me my parcel is lost and to contact you
          </strong>
          <br />
          If you have spoke to Hermes and they say the parcel is lost and to
          speak to us about a refund we are still not able to offer you a refund
          until the 14 days of none movement on the tracking system has passed.
          <br />
          This is because the Hermes chat agents often send a generic reply and
          the parcel is not lost and ends up being delivered 2 days later.{" "}
          <br />
        </p>

        <p>
          <strong className="p-strong">
            My order arrived damaged and items are missing or broken.
          </strong>
          <br />
          If this happens then please contact us with the exact details of what
          is missing, your order number and images of how the parcel arrived.
          You can email these details to hello crucial@seriousid.shop
          <br />
          We will usually refund you for these items unless the total value is
          over £3 and then we can send the items back out to you.
          <br />
          In most cases we will NOT resend items if they have been damaged on
          route, due to the fact they maybe damaged again, you will be refunded
          for these. Regardless of the fact you may need a specific amount for
          an event our policy is to refund and not resend.
        </p>
        <p>
          <strong className="p-strong">
            I have an item missing from my order
          </strong>
          <br />
          If you have a missing item from your order please email hello
          crucial@seriousid.shop with your name, order number, missing item
          details and we can look to resolve.
          <br />
          We are only able to send items out if the value is over £3 due to high
          cost of us posting them back to you. We are also not able to send out
          ‘half items’ such as 4 missing chew bars, you will receive a refund
          for these instead.
        </p>
        <p>
          <strong className="p-strong">
            I have received the wrong item in my order
          </strong>
          <br />
          If you received the wrong item in your order then please email hello
          crucial@seriousid.shop with your name, order number, details of what
          you received and details of what was missing.
          <br />
          We will either resend the item based on value or offer you a refund or
          the original items value.
        </p>
        <p>
          <strong className="p-strong">My item has arrived damaged.</strong>
          <br /> If you receive an item that is damaged please email hello
          crucial@seriousid.shop with your name, order number, details of what
          item was damaged and photographic evidence to support this.
          <br />
          We will only refund you if the item is now inedible either due to the
          packaging becoming open in the box, or the items are smashed beyond
          repair.
          <br />
          During hot weather we do not offer refunds for chocolate products
          which may arrive melted or damaged. We cant control how parcels arrive
          so these items are more likely to melt on route and we advise against
          ordering this during hot weather.
          <br />
          If you order a chocolate bar and nougat bar and the item is snapped or
          broken inside the packaging but the product can still be eaten, we do
          not refund you for this as the item is 100% edible.
          <br />
          In most cases we will NOT resend items if they have been damaged on
          route, due to the fact they maybe damaged again, you will be refunded
          for these. Regardless of the fact you may need a specific amount for
          an event our policy is to refund and not resend.
        </p>
        <p>
          <strong className="p-strong">
            My order is showing a delivered but I do not have it.
          </strong>
          <br />
          If you believe that your order has not arrived then we please email us
          at hello crucial@seriousid.shop with your name, order number and
          details of what you were doing at time of delivery and any further
          information that will prove the order has not been delivered.
          <br />
          You will receive several tracking emails from Hermes as your order
          passes through the system so there are many chances for you to view
          this and be notified of when the order was delivered.
          <br />
          Please check with your neighbours, behind plant pots, in sheds, in
          recycle bins and any other location the parcels are normally left if
          you believe you have not received it.
          <br />
          Please also view the tracking email as this shows an image of the
          delivery.
          <br />
          If you still do not have the parcel then we will contact Hermes for
          further information. This can take up to 7 days to receive a response
          and once we have this information we can resolve for you.
        </p>
        <p>
          <strong className="p-strong">
            I paid for next day delivery and it has not arrived.
          </strong>
          <br />
          Unfortunately we do not guarantee next day delivery or named day
          delivery due to potential issues couriers can face in transporting the
          parcels around the UK. As delivery is out of our control we do not
          offer refunds if the parcel is late or delayed.
        </p>

        <p>
          <strong className="p-strong">
            I sent the order to the wrong address
          </strong>
          <br />
          If you enter an incorrect or incomplete address and do not inform us
          of this and the parcel is then delivered we are not able to offer you
          a refund.
          <br />
          We do ask you check your address on the order confirmation emails and
          so if this information is not passed on we have no way of knowing the
          address is wrong.
        </p>

        <p>
          <strong className="p-strong">
            My order has been returned to sender
          </strong>
          <br />
          If we receive your order back then we will issue you a refund minus
          the original postage costs within 7 days of receiving this parcel
          back.
          <br />
          We are not able to resend the order back out again as the items inside
          are usually smashed due to the extra travelling it has had to do back
          and forth across the country.
          <br />
          You will need to place a new order to receive your items.
          <br />
          For anything else not mentioned above please contact us and we can
          resolve as necessary.
        </p>
        <p>
          <strong className="p-strong">
            Parcel has arrived after I received a refund
          </strong>
          <br /> We will refund customers if the parcel is deemed as “lost”
          after 14 days of none movement on the courier tracking system. However
          if the parcel is then delivered to you we will require payment for the
          goods in full and will seek payment via a direct invoice to you.
          Payment is required for these items within 48 hours.
        </p>
        <p>
          <strong className="p-strong">Order returned to us</strong>
          <br />
          If you order has been returned to us because you entered the address
          wrong you will be refunded the cost of the original order minus the
          delivery fee due to your error.
          <br />
          If your order has been returned to us because you were not available
          at the property and the parcel was returned to us you will be refunded
          the cost of the original order minus the delivery fee due to your
          error.
          <br />
          If your order was returned to us due to being damaged on route you
          will receive a full refund.
          <br />
          We reserve the right to reduce the amount of refund given to you for
          any reason we see fit based on the circumstances as to why the parcel
          was returned.
        </p>
        <hr className="wp-block-heading" />
        <h2>Send a refund request</h2>
        <form onSubmit={handleSubmit} className="form-privacy">
          <header className="css-dspo5n">
            <h1>Contact us</h1>
            <p>
              If you have any questions or queries about your order, or anything
              else please use the contact form below.
            </p>
          </header>
          <div className="css-y79bgc">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {formErrors.name && <p>{formErrors.name}</p>}
          </div>
          <div className="css-y79bgc">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {formErrors.email && <p>{formErrors.email}</p>}
          </div>
          <div className="css-y79bgc">
            <label>Order Number/Postcode:</label>
            <input
              type="text"
              name="orderNumberOrPostcode"
              value={formData.orderNumberOrPostcode}
              onChange={handleChange}
              required
            />
            {formErrors.orderNumberOrPostcode && (
              <p>{formErrors.orderNumberOrPostcode}</p>
            )}
          </div>
          <div className="css-y79bgc">
            <label>Message:</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
            {formErrors.message && <p>{formErrors.message}</p>}
          </div>
          <button
            type="submit"
            className="btn-privacy"
            style={buttonStyle}
            disabled={isSubmitted}
          >
            {isSubmitted ? "Success!" : "Send"}
          </button>
          <footer className="css-j2qwv5">
            <div>
              <span>Powered by</span>
              <img alt="" src="/sweet/helpdesk.svg" />
            </div>
          </footer>
        </form>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
